import ApiService from '@/shared/services/api.service';

export class ResponseTemplatesService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/response-templates', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/response-templates', { ...data } )
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/response-templates/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static remove(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/response-templates/${id}`)
                .then(() => resolve())
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static async listAutocomplete() {
        const response = await ApiService.get('/response-templates');
        return response.data;
    }

    static find(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/response-templates/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }
}
