import responseTemplatesFormStore from '@/modules/response-templates/store/response-templates-form-store';
import responseTemplatesListStore from '@/modules/response-templates/store/response-templates-list-store';

export default {
    namespaced: true,

    modules: {
        form: responseTemplatesFormStore,
        list: responseTemplatesListStore,
    },
};
